<template lang="pug">
	v-card
		v-card-title
			v-text-field(
				v-model="search"
				append-icon="mdi-magnify"
				label="Buscar producto"
				single-line
				class="ml-4"
				max-width="300")
			.flex-grow-1

		v-data-table(
			:headers="headers"
			:items="transaccion"
			:search="search"
			:items-per-page="10"
			class="elevation-0")
			
</template>
<script>
	import NewTransaccion from './NewTransaccionSKU';
	import UploadImages from './UploadImages';

	import PetService from '../services/PetService'
	const petService = new PetService();

	export default {
		name: '',
		data () {
			return {

				search: '',
				headers: [
					{ 
						text: 'SKU', 
						value: 'SKU', 
						type: "number",
						align: "",
						sortable: false
					},
					{ 
						text: 'Descripción', 
						value: 'Descripcion', 
						type: "number",
						align: "",
						sortable: false 
					},
					{ 
						text: 'Gramaje', 
						value: 'Gramaje', 
						type: "number",
						align: "",
						sortable: false
					},
					{ 
						text: 'Mascota', 
						value: 'Mascota', 
						type: "number",
						align: "",
						sortable: false },
					{ 
						text: 'Marca', 
						value: 'Marca', 
						type: "number",
						align: "",
						sortable: false 
					},
					{ 
						text: 'Clase', 
						value: 'Clase', 
						type: "number",
						align: "",
						sortable: false 
					},
					{ 
						text: 'DPDALP', 
						value: 'DPDALP', 
						type: "number",
						align: "",
						sortable: false 
					},
				],
				transaccion : [],

				uid: '',

				editedIndex: -1,
				editedItem: {
				},
				defaultItem: {
				},
			}
		},
		components:{
		},
		mounted() {
			this.fetchTransaccion();
		},
		methods:{
			fetchTransaccion: function(){
				petService.index()
				.then((response)=>{
					this.transaccion = response.data.data
				})
			},
		}
	}
</script>