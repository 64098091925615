<template  lang="pug">
	v-container(class="section-pim")
		v-row(justify="center")
			//- choose opcion
			v-col( cols="12" lg="12" )
				v-tabs(
					background-color="blue-600"
						color="white"
					right)
					v-tab 
						label(class="d-none d-sm-flex") Productos 
						v-icon(class="hidden-sm-and-up") mdi-categories
					v-tab-item
						v-card(class="elevation-0")
							v-card-title(class="pb-0")
								//- h1(align="center" class="title blue-600--text ") Carga  de productos.
							v-card-text(class="py-0 px-0 ")
								v-form(ref="formT")
									v-container
										v-row(align="center" justify="center")
											v-col(cols="12")
												v-alert(type="success" v-if="alert" ) Productos actualizados
												v-alert(type="error"  v-if="alertError.status") {{alertError.msg}}
											v-col(cols="12" sm="4" offset-sm="4")
												v-file-input(
													v-model="files"
													accept=".xlsx" 
													label="Elegir archivo"
													:rules="rulesImg"
													
													)
											v-col(cols="12" sm="3" md="3")
												//- v-tooltip( top )
												//- 	template( v-slot:activator="{ on }")
												//- 		v-btn( icon v-on="on" x-small class="blue-600 mx-3" @click="help_sku= !help_sku")
												//- 			v-icon( color="white") mdi-help
												//- 	span Ver formato de subida

												v-btn(color="blue-500" class="white--text mr-3" :loading="loading" @click="store") cargar
										v-row(align="center" justify="center")
											v-col(cols="12")
												p(class="") <b>Nombre de columnas:</b>
												p  SKU | Descripcion | Gramaje | Mascota | Marca | Clase | DPDALP

								transaccion(ref="tab3")


</template>
<script>

	import XLSX from 'xlsx'
	import Pets from '../../components/Pets'

	import PetService from '../../services/PetService'
	const petService = new PetService();

	export default {
		name: '',

		data() {
			return {
				loading:true,
				help: false,


				items_deleted : [],
				errors: '',
				show_errors: false,
				alert: false,
				alertError: {
					status: false,
					msg:'',
				},

				files:[],
				transaccion:[],

				requiredRules: [
					v => !!v || 'Este campo es necesario',
				],
				rulesImg: [
					v => !v.length || 'Este campo es necesario',
				],
			}
		},
		components:{
			'transaccion' : Pets,
		},
		mounted(){
			this.index()
		},
		methods:{
			index:function(){
				this.loading = true
				petService.index()
				.then((response)=>{
					
					this.transaccion = response.data.data
					this.loading = false;

				})
				.catch((error)=>{
					console.log("Error: No connect with management ")
				})
				.then((response)=>{
					this.loading = false;
				})

			},
			store: function(){
				this.loading = true;
				this.show_error = false;
				
				this.alertError.status = false;
				this.alertError.msg = "";

				if(!this.$refs.formT.validate())
					return
				
				let formData = new FormData();

				formData.append("files", this.files);
				let config = {
					headers : {
						'Content-Type' : 'multipart/form-data',
					}
				}

				petService.store(formData)
				.then((response)=>{
					this.categoria = -1;
					if(response.data.status=='success'){

						this.index();
						this.alert = true;
						setTimeout(()=>{
							this.alert = false;
						},3000)
					}else{
						this.alertError.status = true;
						this.alertError.msg = response.data.msg;
					}
				})
				.catch((error)=>{
					console.log("Error: No connect with recommender ")
				})
				.then((response)=>{
					this.loading = false;
				})
			},
		},
		
	
	}
</script>
