import BackendAuthService from "./BackendAuthService";

export default class PetService extends BackendAuthService {
	
	constructor() {
		super();
		this._path 		= 'replenishment/pets';
	}

	index() {
		return this.httpCall(this._path +'/index', {
			method: 'GET',
		});
	}

	store(data) {
		return this.httpCall(this._path +'', {
			method: 'POST',
			data
		});
	}
	show(id) {
		return this.httpCall(this._path +'/'+id, {
			method: 'GET',
		});
	}

	edit(id) {
		return this.httpCall(this._path +'/'+id+'/edit', {
			method: 'GET',
		});
	}
	update(data, id) {
		return this.httpCall(this._path +'/'+id, {
			method: 'PATCH',
			data
		});
	}

}
